import { getSeasonString } from "@/helper/util";
import { Season } from "@/models/Season";
import { League } from "@prisma/client";

interface getTeamDetailUrlPropps {
  season: Season;
  teamId: string;
  league: League;
}
export function getTeamDetailUrl({
  season,
  teamId,
  league,
}: getTeamDetailUrlPropps) {
  // Important: Teamid ist Season Team ID
  return `/${league.toLowerCase()}/teams/${getSeasonString(season)}/${teamId}`;
}
