import { LeagueType } from "@/models/Gameday";
import { MatchState } from "@prisma/client";
import { isFuture, isPast } from "./time";

export const getGradient = (league: LeagueType) => {
  return league === "Bundesliga"
    ? "from-bundesliga-first to-bundesliga-last"
    : "from-pokal-first to-pokal-last";
};

export const getCurrentMatchesGradient = (
  date: number,
  matchState: MatchState
) => {
  if (matchState === "Active") return "from-orange-first to-orange-last";
  if (isPast(date) || matchState === "Finished")
    return "from-darkBlue-first to-darkBlue-last";
  if (isFuture(date)) return "from-lightBlue-first to-lightBlue-last";
  else return "";
};

export const getTextColor = (matchState: string, isWinner?: boolean) =>
  matchState === "Finished" && isWinner != undefined
    ? isWinner
      ? "text-black"
      : "text-gray-text"
    : "text-black";
