import { useTranslation } from "next-i18next";

interface NoItemsProps {
  message?: string;
}

export function NoItems({ message }: NoItemsProps) {
  const { t } = useTranslation();
  return (
    <div className="bg-white lg:bg-bg-gray py-10 text-center lg:rounded-md">
      {message ?? t("No_Items")}
    </div>
  );
}
