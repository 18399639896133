export const getDate = (date: number) =>
  new Date(date * 1000).toLocaleDateString("de-De", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

export const getDateWithDay = (date: number) =>
  new Date(date * 1000).toLocaleDateString("de-De", {
    weekday: "short",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

export const getDateWithDayLong = (date: number) =>
  new Date(date * 1000).toLocaleDateString("de-De", {
    weekday: "long",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

export const getDateTimeLocalString = (date: number) => {
  const dateObject = new Date(date * 1000);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  const hours = String(dateObject.getHours()).padStart(2, "0");
  const minutes = String(dateObject.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};
export const getDateInputString = (date: number) => {
  const dateObject = new Date(date * 1000);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getDayOfWeek = (date: number) =>
  new Date(date * 1000).toLocaleDateString("de-De", {
    weekday: "long",
  });

export const getDayOfMonth = (date: number) =>
  new Date(date * 1000).toLocaleDateString("de-De", {
    day: "numeric",
  });

export const getMonth = (date: number) =>
  new Date(date * 1000).toLocaleDateString("de-De", {
    month: "short",
  });

export const getDayOfWeekShort = (date: number) =>
  new Date(date * 1000).toLocaleDateString("de-De", {
    weekday: "short",
  });

export const getTime = (date: number) => {
  return new Date(date * 1000).toLocaleTimeString("de-De", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const getTimeScoring = (date: number) => {
  return new Date(date).toLocaleTimeString("de-De", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

// TODO has to be better tested with more data
export const isCurrentWeek = (date: number) => {
  const todayObj = new Date();
  const todayDate = todayObj.getDate();
  const todayDay = todayObj.getDay();

  const useDate = new Date(date * 1000);

  // get first date of week
  const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

  // get last date of week
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

  // if date is equal or within the first and last dates of the week
  return useDate >= firstDayOfWeek && useDate <= lastDayOfWeek;
};

// is seven day in past or future?
export const sevenDaysSpan = (date: number) => {
  const today = new Date();
  const todayDate = today.getDate();
  const todayMonth = today.getMonth();
  const todayYear = today.getFullYear();

  const useDate = new Date(date * 1000);
  const useDateDate = useDate.getDate();
  const useDateMonth = useDate.getMonth();
  const useDateYear = useDate.getFullYear();

  return (
    todayMonth === useDateMonth &&
    todayYear === useDateYear &&
    useDateDate > todayDate - 7 &&
    useDateDate < todayDate + 7
  );
};

export const isToday = (date: number) => {
  const today = new Date().setHours(0, 0, 0, 0);
  const useDate = new Date(date * 1000).setHours(0, 0, 0, 0);
  return today === useDate;
};

export const isPast = (date: number) => {
  const now = Date.now() / 1000;
  return date < now;
};

export const isFuture = (date: number) => {
  const now = Date.now() / 1000;
  return date > now;
};
