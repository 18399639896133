import React, { PropsWithChildren, ReactElement } from "react";
import { Headline } from "./Headline";

interface ChildProps extends PropsWithChildren {
  title: string;
}

export const ResponsiveContainer = ({
  widthLeft = 50,
  rightAuto = false,
  children,
  order,
}: {
  widthLeft?: number;
  rightAuto?: boolean;
  order?: string[];
  children?:
    | ReactElement<ChildProps>
    | [ReactElement<ChildProps>, ReactElement<ChildProps> | undefined];
}) => {
  if (!Array.isArray(children) && !widthLeft) {
    widthLeft = 50;
  }
  const centerGap = widthLeft === 50 ? "lg:flex-1" : "";
  return (
    <div className={`flex flex-col lg:flex-row gap-4 max-h-full`}>
      {React.Children?.map(children, (child, i) => {
        if (!child) return;

        return i === 0 ? (
          <div
            className={`lg:w-[${widthLeft}%] flex flex-col h-full ${centerGap} ${
              order && order.length > 0 ? order[i] : ""
            }`}
          >
            {Array.isArray(child.props.children) ? (
              React.Children?.map(child.props.children, (c) => {
                if (!c) return;

                return (
                  <>
                    {c?.props.title && (
                      <Headline level={3} className="ml-4 ">
                        {c.props.title}
                      </Headline>
                    )}
                    <div className="lg:bg-bg-gray lg:p-4 lg:rounded-lg h-full">
                      {c}
                    </div>
                  </>
                );
              })
            ) : (
              <>
                {child.props.title && (
                  <Headline level={3} className="ml-4 ">
                    {child.props.title}
                  </Headline>
                )}
                <div className="lg:bg-bg-gray lg:p-4 lg:rounded-lg h-full">
                  {child}
                </div>
              </>
            )}
          </div>
        ) : (
          <div
            className={`
            lg:flex-1 
            lg:w-[${rightAuto ? "auto" : 100 - widthLeft + "%"}]
            
            flex flex-col
            `}
          >
            {Array.isArray(child.props.children) ? (
              React.Children?.map(child.props.children, (c) => {
                if (!c) return;

                return (
                  <>
                    {c?.props.title && (
                      <Headline level={3} className="ml-4 ">
                        {c.props.title}
                      </Headline>
                    )}
                    <div className="lg:bg-bg-gray lg:p-4 lg:rounded-lg h-full">
                      {c}
                    </div>
                  </>
                );
              })
            ) : (
              <>
                {child.props.title && (
                  <Headline level={3} className="ml-4 ">
                    {child.props.title}
                  </Headline>
                )}
                <div className="lg:bg-bg-gray lg:p-4 lg:rounded-lg h-full">
                  {child}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
