import Link from "next/link";
import { FiArrowRight } from "react-icons/fi";
import { News } from "../../models/types";
import { Headline } from "../common/Headline";
import { NewsImage } from "../common/Images";
import { useTranslation } from "next-i18next";

interface NewsCardProps {
  news: News;
  type?: "big" | "small";
  isHome?: boolean;
  className?: string;
}
export function NewsCard({
  news,
  type = "small",
  isHome = false,
  className,
}: NewsCardProps) {
  const { t } = useTranslation();
  return (
    <div
      className={`newsteaser overflow-hidden ${
        type === "small" ? "lg:rounded-md" : ""
      } ${isHome ? "lg:shadow-md" : ""} ${className ? className : ""}`}
    >
      <Link href={`/news/post/${news.id}`}>
        <div
          className={`grid grid-cols-3 ${
            type === "small" ? "lg:grid-cols-1" : ""
          }`}
        >
          <div className="newsteaser_thumb overflow-hidden lg:bg-transparent lg:max-w-md">
            <NewsImage news={news} type={type} />
          </div>
          <div
            className={`block p-4 col-span-2 ${
              type == "big" ? "lg:max-w-[500px]" : ""
            }`}
          >
            {/* TODO: Add Correct locale */}
            <div
              className={`border-l-blue border-l-2 pl-1 text-sm newsteaser_date ${
                type === "big" ? "lg:text-lg" : ""
              }`}
            >
              {new Date(news.published_at).toLocaleDateString("de-De", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
            </div>
            <div
              className={`uppercase text-blue border-l-blue border-l-2 pl-1 text-sm newsteaser_category ${
                type === "big" ? "lg:text-lg" : ""
              }`}
            >
              {t(
                news.primary_tag?.name ??
                  news.tags.filter((t) => t.slug !== "hash-en")[0]?.name ??
                  "TTBL.de"
              )}
            </div>
            <div className={`pl-1.5 max-lg:leading-5 line-clamp-2 text-xl`}>
              {news.title}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export function TopNewsCard({ news }: NewsCardProps) {
  const { t } = useTranslation();
  return (
    <Link
      href={`/news/post/${news.id}`}
      className={`block relative text-white w-full h-[300px] lg:h-[400px] bg-no-repeat bg-center bg-primary`}
      style={{
        backgroundImage: `url(${news.feature_image})` || "/news_dummy.png",
        backgroundSize: "auto 100%",
      }}
    >
      <div className="container relative h-full">
        <div className="absolute bottom-[5%] lg:bottom-[20%] left-4 right-4 rounded-md md:w-2/3 lg:w-1/2 bg-[rgba(5,23,38,0.777)] p-3">
          <div className="block px-2 border-l-yellow border-l-4">
            {/* TODO: Add Correct locale */}
            <div className="text-yellow font-antonio">
              <div className="">
                {new Date(news.published_at).toLocaleDateString("de-De", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </div>
              <div className="text-lg">
                {t(
                  news.primary_tag?.name ??
                    news.tags.filter((t) => t.slug !== "hash-en")[0]?.name ??
                    "TTBL.de"
                )}
              </div>
            </div>
          </div>
          <Headline level={3} className="text-2xl mb-1 ml-3">
            {news.title}
          </Headline>
          <p className="text-yellow align-middle text-sm pl-3 pr-2 mt-2 group inline-flex space-x-1 items-center">
            <FiArrowRight className="mr-1 transition-transform transform group-hover:translate-x-0.5 duration-300" />
            <span>mehr</span>
          </p>
        </div>
      </div>
    </Link>
  );
}
