import { getCurrentMatchesGradient } from "@/helper/color";
import {
  getDayOfMonth,
  getDayOfWeekShort,
  getMonth,
  getTime,
  sevenDaysSpan,
} from "@/helper/time";
import { SeasonTeam } from "@/models/Team";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useEffect } from "react";
import { getSeasonString } from "../../helper/util";
import { Match, MatchWithTeamsAndVenueAndGameday } from "../../models/Match";
import { Season } from "../../models/Season";
import { TeamImage } from "../common/Images";

interface CurrentMatchesListProps {
  matches: MatchWithTeamsAndVenueAndGameday[];
  season: Season;
}
export function CurrentMatchesList({
  matches,
  season,
}: CurrentMatchesListProps) {
  const now = Date.now() / 1000;
  // const nearestMatch = [...matches].reduce(
  //   (a, b) =>
  //   Math.abs(now - a.timeStamp) - Math.abs(now - b.timeStamp)
  // )[0];
  useEffect(() => {
    const nearestMatches = matches.reduce<Match[]>((acc, match) => {
      if (acc.length > 0) {
        if (
          Math.abs(now - match.timeStamp) < Math.abs(now - acc[0].timeStamp)
        ) {
          acc = [match];
        } else if (
          Math.abs(now - match.timeStamp) === Math.abs(now - acc[0].timeStamp)
        ) {
          acc.push(match);
        }
      } else {
        acc.push(match);
      }
      return acc;
    }, []);
    const nearestMatch = nearestMatches.slice(-1)[0];
    const element = document.getElementById(nearestMatch.id);
    element?.scrollIntoView({
      behavior: "smooth",
      inline: "center",
      block: "end",
    });
  });
  return (
    <div className="overflow-x-auto">
      <div className="inline-block">
        <div className="flex gap-4 w-ful">
          {matches.map((match) => (
            <CurrentMatchesCard
              key={match.id}
              id={match.id}
              match={match}
              season={season}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

interface CurrentMatchesCardProps {
  match: MatchWithTeamsAndVenueAndGameday;
  season: Season;
  id: string;
}
function CurrentMatchesCard({ match, season, id }: CurrentMatchesCardProps) {
  // TODO: Get Locale + Translations
  const isInactive = match.matchState === "Inactive";
  const { t } = useTranslation();
  return (
    <Link
      href={`/${
        match.league === "Bundesliga" ? "bundesliga" : "pokal"
      }/gameday/${getSeasonString(season)}/${match.gameday.index}/${match.id}`}
      className="bg-white rounded-md border border-gray-light overflow-hidden h-min self-end"
      title={`${match.homeTeam.seasonTeam.name} - ${match.awayTeam.seasonTeam.name} (${match.gameday.name})`}
      id={id}
    >
      {match.gameday.type !== "Normal" && (
        <div className="bg-neutral-200 p-1 text-sm text-darkBlue-text">{`${
          match.gameday.league
        } - ${t(match.gameday.type)}`}</div>
      )}
      <div className="flex ">
        <div
          className={`
           flex flex-col justify-between items-center w-[40px] py-2 text-white font-antonio font-bold text-xl bg-gradient-to-b ${
             match.gameday.type === "Normal" ? "h-auto" : ""
           } 
          ${getCurrentMatchesGradient(match.timeStamp, match.matchState)}
        `}
        >
          <div className="text-center">
            <div className="font-antonio text-xl font-bold leading-none">
              {sevenDaysSpan(match.timeStamp)
                ? getDayOfWeekShort(match.timeStamp)
                : getDayOfMonth(match.timeStamp)}
            </div>
            {!sevenDaysSpan(match.timeStamp) && (
              <div className="font-antonio text-xs mt-1">
                {getMonth(match.timeStamp)}
              </div>
            )}
            {match.matchState === "Active" && (
              <div className="inline-flex bg-white rounded-sm pt-0.5 pb-1 px-0.5 font-antonio font-bold text-sm leading-none text-orange-last">
                LIVE
              </div>
            )}
          </div>

          <div className="font-antonio text-sm font-bold">
            {/* TODO league short */}
          </div>
        </div>
        <div className={`py-2 px-4 flex flex-col justify-center self-center`}>
          <CurrentMatchesCardTeam
            team={match.homeTeam.seasonTeam}
            games={match.homeGames}
            isInactive={isInactive}
            match={match}
          />
          <div className="h-px w-full bg-gray-light my-1" />
          <CurrentMatchesCardTeam
            team={match.awayTeam.seasonTeam}
            games={match.awayGames}
            isInactive={isInactive}
            match={match}
          />
        </div>
        {isInactive && (
          <div className="pr-4 text-center inline-flex justify-center font-antonio font-bold text-sm text-dark-blue h-min self-center py-6">
            {getTime(match.timeStamp)}
            <br />
            Uhr
          </div>
        )}
      </div>
    </Link>
  );
}

const CurrentMatchesCardTeam = ({
  team,
  games,
  isInactive,
  match,
}: {
  match: Match;
  team: SeasonTeam;
  games: number;
  isInactive: boolean;
}) => {
  return (
    <div className="flex items-center space-between justify-center gap-8">
      <TeamImage team={team} size="sm" className="" />
      {!isInactive && (
        <div
          className={`font-antonio font-bold text-2xl  ${
            match.matchState === "Active" ? "text-orange-last" : "text-black"
          }`}
        >
          {games}
        </div>
      )}
    </div>
  );
};
