import { ResponsiveContainer } from "@/components/common/ResponsiveContainer";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Link from "next/link";
import { CurrentMatchesList } from "../components/CurrentMatches/CurrentMatchesList";
import { Footer } from "../components/Footer";
import { PlayerRankingTable } from "../components/PlayerRankingTable";
import { Nav } from "../components/common/FrontendNav";
import { Headline } from "../components/common/Headline";

import { Table } from "../components/common/Table";

import { getCurrentMatches } from "@/data/match";
import { getHomeNews } from "@/data/news";
import { getCurrentBundesligaTeams } from "@/data/teams";
import Head from "next/head";
import { NewsCard, TopNewsCard } from "../components/News/NewsCard";
import { getCurrentBundesligaPlayers, getCurrentSeason } from "../helper/api";
import { LeaguePlayerWithTeam } from "../models/LeaguePlayer";
import { LeagueTeam } from "../models/LeagueTeam";
import { MatchWithTeamsAndVenueAndGameday } from "../models/Match";
import { Season } from "../models/Season";
import { News } from "../models/types";

export async function getServerSideProps(context: any) {
  const { res } = context;
  res.setHeader(
    "Cache-Control",
    "public, max-age=60, stale-while-revalidate=90"
  );
  //Get Current Bundesliga
  const season = await getCurrentSeason();
  const bundesligaTeams = await getCurrentBundesligaTeams();
  //Current matches
  const currentMatches = await getCurrentMatches();
  let players = await getCurrentBundesligaPlayers({});
  const filteredPlayers = players.filter((p) => p.rank !== 0);
  if (filteredPlayers.length > 0) {
    players = filteredPlayers.slice(0, 10);
  } else {
    players = players.slice(0, 10);
  }

  const { news, topNews } = await getHomeNews({ locale: context.locale });

  return {
    props: {
      news,
      topNews,
      currentMatches,
      bundesligaTeams,
      players,
      season,
      ...(await serverSideTranslations(context.locale)),
    },
  };
}

interface HomePageProps {
  news: News[];
  topNews?: News;
  bundesligaTeams: LeagueTeam[];
  currentMatches: MatchWithTeamsAndVenueAndGameday[];
  season: Season;
  players: LeaguePlayerWithTeam[];
}
export default function HomePage({
  news,
  topNews,
  bundesligaTeams,
  currentMatches,
  season,
  players,
}: HomePageProps) {
  const { t } = useTranslation();
  return (
    <>
      <Head>
        <title>{`${t("Table_Tennis")} Bundesliga - TTBL`}</title>
      </Head>
      <div>
        <Nav />

        {currentMatches.length > 0 && (
          <div className="container py-4">
            <Headline level={3}>{t("Current_Games")}</Headline>
            <CurrentMatchesList matches={currentMatches} season={season} />
          </div>
        )}
        {topNews && (
          <div className="">
            <TopNewsCard news={topNews} />
          </div>
        )}
        {news.length > 0 && (
          <div className={`container ${topNews ? "md:-mt-12" : ""}`}>
            <div className="md:grid md:grid-cols-4 md:gap-4">
              {news.map((e) => (
                <NewsCard key={e.id} news={e} isHome />
              ))}
            </div>
            <div className="flex justify-center mt-5 md:mt-10 mb-5">
              <Link href="/news/all/1" className="btn">
                {t("All_News")}
              </Link>
            </div>
          </div>
        )}
        <div className="container pt-8">
          <Link
            className="block w-full lg:w-[1000px] mx-auto"
            style={{
              backgroundImage: "url(/TT-Banner-2000x260.jpg)",
              backgroundSize: "auto 100%",
              objectFit: "contain",
              aspectRatio: "2000/260",
            }}
            href="https://www.dyn.sport/aktion/tischtennis?utm_source=tischtennis-bundesliga&utm_medium=media-direct&utm_campaign=C:DE_L:DE_SC:Club_CT:CXT_ST:Tischtennis_G:FIX&utm_content=CT:CXT_CTD:tischtennis-bundesliga-RoS&utm_term=ST:Tischtennis_CF:Banner"
            target="_blank"
          />
        </div>
        {bundesligaTeams.length > 0 && players.length > 0 && (
          <div className="container container_py container-padding-responsive">
            <ResponsiveContainer widthLeft={50}>
              <div title={t("Table") as string}>
                <div>
                  <div>
                    <Table
                      teams={bundesligaTeams}
                      type="small"
                      season={season}
                    />
                  </div>
                  <div className="flex justify-center mt-5 md:mt-10 mb-5">
                    <Link href="/bundesliga/table" className="btn">
                      {t("Show_Entire_Table")}
                    </Link>
                  </div>
                </div>
              </div>
              <div title={t("Title_Rankings") as string}>
                <div>
                  <PlayerRankingTable players={players} />
                  <div className="flex justify-center mt-5 md:mt-10 mb-5">
                    <Link href="/bundesliga/ranking/all/single" className="btn">
                      {t("Show_Player_Ranking")}
                    </Link>
                  </div>
                </div>
              </div>
            </ResponsiveContainer>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}
