import { getTeamDetailUrl } from "@/helper/urlHelper";
import { Season } from "@/models/Season";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { LeagueTeam } from "../../models/LeagueTeam";
import { TeamImage } from "./Images";
import { NoItems } from "./NoItems";

type TableType = "big" | "small" | "xs";
interface TableProps {
  teams: LeagueTeam[];
  type?: TableType;
  season: Season;
}
export function Table({ teams, type = "big", season }: TableProps) {
  const { t } = useTranslation();
  if (teams.length === 0) {
    return (
      <div className="container">
        <NoItems />
      </div>
    );
  }
  return (
    <div className="table_scrollable max-w-[900px]">
      <table>
        <tbody>
          <tr className="table-header row_item break-words">
            <th className="text-center w-4">{t("Label_Rank")}</th>
            <th className="w-4/12">{t("Label_Team")}</th>
            {type === "big" && (
              <th className=" text-center">{t("Label_Encounters")}</th>
            )}
            <th className="text-center">{t("Label_Wins")}</th>

            <th className=" text-center">{t("Label_Losses")}</th>
            {type === "big" && (
              <th className="text-center">{t("Label_Games")}</th>
            )}
            {type === "big" && (
              <th className="max-lg:hidden text-center ">{t("Label_+/-")}</th>
            )}
            <th className="text-center ">{t("Label_Points")}</th>
          </tr>
          {teams
            .sort((a, b) => a.rank - b.rank)
            .map((t) => (
              <TableCard team={t} key={t.id} type={type} season={season} />
            ))}
        </tbody>
      </table>
    </div>
  );
}

interface TableCardProps {
  team: LeagueTeam;
  type: TableType;
  season: Season;
}
function TableCard({ team, type, season }: TableCardProps) {
  const rankStyle =
    team.rank <= 4 ? "winning" : team.rank >= 11 ? "losing" : "";
  return (
    <tr className="row_item break-words">
      <td className="flex items-center my-2">
        <div className={` text-center px-3 py-1 ${rankStyle}`}>{team.rank}</div>
        {/* <div className="table-rank-up ml-2">
          <FiChevronUp />
        </div> */}
      </td>
      <td>
        <Link
          className="flex items-center"
          href={getTeamDetailUrl({
            league: "Bundesliga",
            season,
            teamId: team.seasonTeam.id,
          })}
          title={team.seasonTeam.name}
        >
          <TeamImage team={team.seasonTeam} size="md" />
          {type === "big" ? (
            <>
              <div className="max-lg:hidden ml-4 whitespace-nowrap">
                {team.seasonTeam.name}
              </div>
              <div className="lg:hidden ml-2 whitespace-nowrap">
                {team.seasonTeam.shortname}
              </div>
            </>
          ) : type === "small" ? (
            <>
              <div className="ml-4 whitespace-nowrap max-lg:hidden text-lg">
                {team.seasonTeam.name}
              </div>
              <div className="lg:hidden ml-2 whitespace-nowrap text-lg">
                {team.seasonTeam.shortname}
              </div>
            </>
          ) : (
            <div className="ml-4 whitespace-nowrap">
              {team.seasonTeam.shortname}
            </div>
          )}
        </Link>
      </td>
      {type === "big" && <td className="text-center">{team.matchCount}</td>}

      <td className="text-center">{team.matchWins}</td>

      <td className=" text-center">{team.matchLosses}</td>
      {type === "big" && (
        <td className="text-center whitespace-nowrap tracking-wide">{`${team.gameWins} : ${team.gameLosses}`}</td>
      )}
      {type === "big" && (
        <td className="max-lg:hidden text-center tracking-wide">
          {team.gameWins - team.gameLosses}
        </td>
      )}
      <td className="text-center font-bold">{`${team.matchWins * 2} : ${
        team.matchLosses * 2
      }`}</td>
    </tr>
  );
}
